// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-de-js": () => import("/opt/build/repo/src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-page-2-de-js": () => import("/opt/build/repo/src/pages/page-2.de.js" /* webpackChunkName: "component---src-pages-page-2-de-js" */),
  "component---src-pages-page-2-en-js": () => import("/opt/build/repo/src/pages/page-2.en.js" /* webpackChunkName: "component---src-pages-page-2-en-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

